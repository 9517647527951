<template>
  <div class="page-wrapper">
    <div class="header">
      <headerComponent pageType="home" />
    </div>
    <main class="main-wrapper">
      <section class="section-hero">
        <div class="hero-visible-height">
          <div class="hero-visible-area">
            <div class="hero-blur-background"></div>
            <div class="hero-blur-background is-second"></div>
          </div>
        </div>
        <div class="padding-global">
          <div class="base-container">
            <div class="hero-main-split">
              <div class="hero-left">
                <div class="margin-bottom margin-xsmall">
                  <h1 data-w-id="bfcd5c31-c18d-9a02-ce13-408abd8cfc94" class="hero-title">
                    Your <span class="hero-text-span">Growth </span> <br />
                    Our <span class="hero-text-span">Expertise </span>
                  </h1>
                </div>
                <div class="margin-bottom margin-xlarge">
                  <!-- <p data-w-id="bfcd5c31-c18d-9a02-ce13-408abd8cfcb5" style="opacity:0;-webkit-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="hero-info">From clicks to sales, we’ve got you covered. ArtemisAds’ stellar multi-channel marketing services help elevate your brands, and boost your sales!</p> -->
                  <p data-w-id="bfcd5c31-c18d-9a02-ce13-408abd8cfcb5" class="hero-info">
                    From clicks to sales, we’ve got you covered. ArtemisAds’
                    stellar multi-channel marketing services help elevate your
                    brands, and boost your sales!
                  </p>
                </div>
                <!-- <div data-w-id="b52593f0-1f46-1a91-266d-600527a5234d" style="opacity:0;-webkit-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="button-wrapper">
                  <a data-w-id="ac7ac361-65a3-49a5-cc50-02cdd7ace0ef" href="#contact" class="button-primary w-button" style="padding: 18px 76px;">contact us</a>
                </div> -->
                <div data-w-id="b52593f0-1f46-1a91-266d-600527a5234d" class="button-wrapper">
                  <a data-w-id="ac7ac361-65a3-49a5-cc50-02cdd7ace0ef" href="#contact" class="button-primary w-button"
                    style="padding: 18px 76px">contact us</a>
                </div>
                <img src="../../assets/images/img/amazon@2x.png" loading="lazy" width="302"
                  data-w-id="b52593f0-1f46-1a91-266d-600527a5234d" alt="amazon" class="amazon-logo-img" />
              </div>
              <div class="hero-right">
                <div class="hero-right-images">
                  <img src="../../assets/images/img/bg-1@2x.png" data-w-id="b52593f0-1f46-1a91-266d-600527a5234d"
                    loading="lazy" alt="Hero Main Image" class="hero-main-image" />
                </div>
              </div>
            </div>
            <div class="spacer-huge"></div>
          </div>
        </div>
      </section>
      <section class="section-customer" id="clients">
        <div class="base-container">
          <div class="customer-content-wrapper padding-section-regular section-work-process">
            <div class="padding-global">
              <div class="margin-bottom margin-huge">
                <h2 class="section-customer-title">
                  Trusted by
                  <span class="title-span text-weight-semibold">Hundreds </span><span
                    class="text-color-primary text-weight-semibold"></span>
                  of Brands like you
                </h2>
              </div>
            </div>
            <div class="customoer-logos">
              <div class="customer-logo-contain margin-bottom margin-medium">
                <div class="customer-logo-wrapper" v-for="(brand, index) in ['Songmics',
                  'Ulike',
                  'Narwal',
                  'Casabrews']" :key="index">
                  <img :src="'images/home20250317/' + brand.toLowerCase() + '.png'" loading="lazy" :alt="brand"
                    class="customer-logo" />
                </div>
              </div>
              <div class="customer-logo-contain margin-bottom margin-medium">
                <div class="customer-logo-wrapper" v-for="(brand, index) in ['Philips',
                  'Grownsy',
                  'Mediheal',
                  'Hoto',]" :key="index">
                  <img :src="'images/home20250317/' + brand.toLowerCase() + '.png'" loading="lazy" :alt="brand"
                    class="customer-logo" />
                </div>
              </div>
              <div class="customer-logo-contain margin-bottom margin-medium">
                <div class="customer-logo-wrapper" v-for="(brand, index) in ['Sihoo',
                  'phantom',
                  'Yeelight',
                  'Romoss',]" :key="index">
                  <img :src="'images/home20250317/' + brand.toLowerCase() + '.png'" loading="lazy" :alt="brand"
                    class="customer-logo" />
                </div>
              </div>
              <div class="customer-logo-overlay" data-v-252597e8=""></div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-contact-cta" id="contact">
        <div class="container-fluid">
          <div class="contact-cta-wrapper" style="padding-bottom: 90px;">
            <img src="../../assets/images/home/OBJECTS-1.png" loading="lazy" alt="CTA Shape One" class="cta-shape-one">
            <img src="../../assets/images/home/OBJECTS-2.png" loading="lazy"
              sizes="(max-width: 1279px) 100vw, (max-width: 1919px) 20vw, 552px"
              srcset="../../assets/images/home/OBJECTS-2-p-500.png 500w, ../../assets/images/home/OBJECTS-2.png 552w"
              alt="CTA Shape One" class="cta-shape-two">
            <div class="flex-and-center">
              <div class="margin-bottom margin-xsmall">
                <h2 data-w-id="6b1fe4bc-bb6d-b0f8-1a63-0060151a0ba3"
                  style="-webkit-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:1"
                  class="heading-style-h3 text-color-white"><span class="title-span is-contact-cta">Contact</span> Us
                </h2>
              </div>
            </div>
            <div data-w-id="6b1fe4bc-bb6d-b0f8-1a63-0060151a0ba3" class="contact-us-form">
              <div class="form-block w-form">
                <div id="email-form" name="email-form" novalidate data-name="Email Form" class="form"
                  data-wf-page-id="66d56698539c62c4c39f2589" data-wf-element-id="355ee6f0-3374-10ea-6954-0fc2798e7f29">
                  <div class="contact-form-input">
                    <label for="" class="input-field">Your name</label>
                    <input ref="nameRef" class="text-field w-input" maxlength="256" name="name" data-name="Name"
                      placeholder="Your Name" type="text" id="name">
                  </div>
                  <div class="contact-form-input">
                    <label for="email" class="input-field required">Your email</label>
                    <input ref="mailRef" class="text-field w-input" maxlength="256" name="email" data-name="Email"
                      placeholder="Your Email" type="email" id="email" required>
                  </div>
                  <div id="w-node-_355ee6f0-3374-10ea-6954-0fc2798e7f36-c39f2589" class="contact-form-input">
                    <label for="Message" class="input-field required">How can we help you?</label>
                    <textarea ref="messageRef" placeholder="Write Massage" maxlength="5000" id="message" name="content"
                      data-name="Message" class="text-message w-input" required></textarea>
                  </div>
                  <button ref="sendBtnRef" @click="sendMail" data-wait="Please wait..."
                    id="w-node-_355ee6f0-3374-10ea-6954-0fc2798e7f3a-c39f2589" class="button-secondary w-button">Submit
                    Now</button>
                </div>
                <div class="success-message w-form-done">
                  <div class="text-color-white">Thank you! Your submission has been received!</div>
                </div>
                <div class="w-form-fail">
                  <div>Oops! Something went wrong while submitting the form.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footerComponent pageType="home" />
    </main>
    <a @click="scrollToEle('#app')" class="scrolll-top w-inline-block">
      <div></div>
    </a>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { homeApi } from '../../server/index'
import headerComponent from './headerComponent.vue'
import footerComponent from './footerComponent.vue'

const nameRef = ref();
const messageRef = ref();
const mailRef = ref();
const sendBtnRef = ref();
const route = useRoute();


function scrollToEle(ele) {
  document.querySelector(ele).scrollIntoView({
    behavior: 'smooth'
  })
}

const sendMail = async () => {
  const name = nameRef.value.value;
  const content = messageRef.value.value;
  const email = mailRef.value.value;
  if (name && content && email) {
    sendBtnRef.value.textContent = 'Please wait...';
    const params = {
      name, content, email,
    };
    const res = await homeApi.contactUs(params);
    if (res.success) {
      sendBtnRef.value.textContent = 'Successfully';
    }

    const timer = setTimeout(() => {
      sendBtnRef.value.textContent = 'Submit Now';
      clearTimeout(timer);
    }, 2000)

  }
}

onMounted(() => {
  const point = route.query.point;

  if (point) {
    scrollToEle(`#${point}`)
  }
})

</script>

<style lang="css" scoped>
@import './index.css';
</style>

<style lang="less" scoped>
.page-wrapper {
  min-width: 1440px !important;
  overflow: scroll;
  color: var(--dark--dark-3);
  font-family: Sora, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.header {
  left: 0;
  right: 0;
  top: 10px;
  z-index: 1;
  position: absolute;
}
</style>